<template>
    <div class="ces-main" style="height: 100%">
        <div class="boxbutton">
            <el-row>
                <el-col :span="16">
                    <div class="grid-content bg-purple">
                        <!--<el-button type="primary" plain size="mini" v-if="signforSearchFlag" @click="signforSearch">查询</el-button>-->
                        <el-button type="primary" plain size="mini" v-if="exceptionMoifyFlag" @click="exceptionMoify">修改</el-button>
                        <el-button type="primary" plain size="mini" v-if="exceptionDelFlag" @click="exceptionDel">删除</el-button>
                    </div>
                </el-col>
                <el-col :span="8">
                    <div class="grid-content bg-purple">
                        <el-input maxlength="50" placeholder="请输入运单号" v-model="AccurateAndUnique_orderNo" size="mini" style="width: 250px"  class="input-with-input">
                            <el-button slot="append" icon="el-icon-search" @click="handleQuery"></el-button>
                        </el-input>
                        <el-button type="primary" size="mini" icon="el-icon-zoom-in" style="float: right" @click="dialog = true">高级查询</el-button>
                    </div>
                </el-col>
                <el-drawer
                        title="异常管理高级查询条件"
                        :before-close="cancelForm"
                        :visible.sync="dialog"
                        direction="rtl"
                        custom-class="demo-drawer"
                        ref="drawer"
                >
                    <div class="demo-drawer__content">
                        <el-row>
                            <el-col>
                                <div class="grid-content bg-purple">
                                    <el-card class="box-card">
                                        <div>
                                            <el-form label-width="140px" :model="dataForms" ref="dataForms" size="mini" label-position="left">
                                                <el-form-item label="车次号" prop="batchNo">
                                                    <el-input  maxlength="18" v-model="dataForms.batchNo"></el-input>
                                                </el-form-item>
                                                <el-form-item label="异常类型" prop="exceType">
                                                    <el-select v-model="dataForms.exceType" placeholder="请选择运单状态" style="width: 100%">
                                                        <el-option label="请选择" value=""></el-option>
                                                        <el-option label="运单异常" value="0"></el-option>
                                                        <el-option label="车次异常" value="1"></el-option>
                                                    </el-select>
                                                </el-form-item>
                                                <el-form-item label="公司ID" prop="companyId">
                                                    <el-input  v-model="dataForms.companyId"></el-input>
                                                </el-form-item>
                                                <el-form-item label="公司名称" prop="companyName">
                                                    <el-input  v-model="dataForms.companyName"></el-input>
                                                </el-form-item>
                                                <el-form-item label="车牌" prop="truckNo">
                                                    <el-input  v-model="dataForms.truckNo"></el-input>
                                                </el-form-item>
                                                <el-form-item label="查询开始时间" prop="crateTimeStrat">
                                                    <el-date-picker
                                                            v-model="dataForms.crateTimeStrat"
                                                            type="datetime"
                                                            placeholder="选择日期时间"
                                                            align="right">
                                                    </el-date-picker>
                                                </el-form-item>
                                                <el-form-item label="查询结束时间" prop="crateTimeEnd">
                                                    <el-date-picker
                                                            v-model="dataForms.crateTimeEnd"
                                                            type="datetime"
                                                            placeholder="选择日期时间"
                                                            align="right">
                                                    </el-date-picker>
                                                </el-form-item>
                                            </el-form>
                                            <div slot="footer" class="dialog-footer" style="text-align: center">
                                                <el-button size="mini" v-if="exceptionSaveFlag" type="primary" @click="handleClose" :loading="loading">{{ loading ? '提交中 ...' : '查 询' }}</el-button>
                                                <el-button size="mini" type="warning" @click="cancelForm">关闭</el-button>
                                            </div>
                                        </div>
                                    </el-card>
                                </div>
                            </el-col>

                        </el-row>
                    </div>
                </el-drawer>
            </el-row>
        </div>
        <div class="tableMain">
            <ces-table
                    size='mini'
                    :isSelection='true'
                    :isIndex='true'
                    :isPagination='true'
                    :isHandle='true'
                    v-loading='loading'
                    :tableData='tableData'
                    :tableCols='tableCols'
                    :pagination='pagination'
                    @handleImgClick="handleImgClick"
                    @refresh="handleCurrentChange"
                    ref='cesTable'
            >
            </ces-table>
        </div>
        <el-dialog :visible.sync="dialogVisible_imgUrl" :before-close="handleCloseImgDialog">
            <el-image
                    :src="dialogImageUrl_via"
                    :z-index="3000"
                    :preview-src-list="dialogImageUrl_via_srcList">
            </el-image>
        </el-dialog>
    </div>
</template>

<script>
    //引入组件
    import cesTable from '../../components/common/table.vue'
    import Drawer from '../../components/common/drawer.vue'
    import axios from 'axios'
    import arrayQuery from '../../components/arrayQuery.js'
    import qs from 'qs'
    import 'element-ui/lib/theme-chalk/index.css'
    export default {
        data(){
            return {
                table: false,
                dialog: false,
                loading: false,
                customerCommEntFlag:false,
                colors: ['red', '#F7BA2A', '#FF9900'],
                AccurateAndUnique_orderNo:'',
                dialogVisible_imgUrl:false,
                dataForms:{
                    batchNo:'',
                    exceType:'',
                    companyId:'',
                    companyName:'',
                    truckNo:'',
                    crateTimeStrat:'',
                    crateTimeEnd:''
                },
                dialogImageUrl_via:'',
                dialogImageUrl_via_srcList:[],
                formLabelWidth: '80px',
                timer: null,
                apiId:'null',
                fieldList:[],
                // 表格
                tableData:[],//表格数据
                tableCols:[ //表格列数据
                    {label:'车次号',prop:'batchNo',width:'170',align:'center'},
                    {label:'运单编号',prop:'orderNo',width:'160',align:'center'},
                    {label:'异常类型',prop:'exceType',align:'center',
                        formatter: (row) => {
                            if (row.exceType == 0) {
                                return "运单异常";
                            } else if (row.exceType == 1) {
                                return "车次异常";
                            } else {
                                return "";
                            }
                        }
                    },
                    {label:'公司名称',prop:'companyName',align:'center',width:'120'},
                    {label:'车牌',prop:'truckNo',align:'center',width:'120'},
                    {label:'异常描述',prop:'exceDesc',align:'center',width:'120'},
                    {label:'异常附件1',type: "imgLink",prop:'exceFileUrl1',align:'center',width:'120',
                        formatter: (row) => {
                            if (row.exceFileUrl1 == '' || row.exceFileUrl1 == null || row.exceFileUrl1 == 'null') {
                                return "";
                            } else {
                                return "查看附件";
                            }
                        }
                    },
                    {label:'异常附件2',type: "imgLink",prop:'exceFileUrl2',align:'center',width:'120',
                        formatter: (row) => {
                            if (row.exceFileUrl2 == '' || row.exceFileUrl2 == null || row.exceFileUrl2 == 'null') {
                                return "";
                            } else {
                                return "查看附件";
                            }
                        }
                    },
                    {label:'异常附件3',type: "imgLink",prop:'exceFileUrl3',align:'center',width:'120',
                        formatter: (row) => {
                            if (row.exceFileUrl3 == '' || row.exceFileUrl3 == null || row.exceFileUrl3 == 'null') {
                                return "";
                            } else {
                                return "查看附件";
                            }
                        }
                    },
                    {label:'提交时间',prop:'createTime',align:'center',width:'160',
                        formatter: (row, column) => {
                            if(row.createTime == null){
                                return ''
                            }else {
                                return this.$moment(row.createTime).format("YYYY-MM-DD HH:mm:ss")
                            }

                        }
                    },
                    {label:'异常发生地址',prop:'exceAddress',align:'center',width:'120'},
                    {label:'提交人',prop:'creator',align:'center',width:'120'},
                    {label:'提交人联系方式',prop:'mobile',align:'center',width:'120'},
                ],
                pagination:{ //分页数据
                    pageSize:50,
                    pageNum:1,
                    total:0
                },
                merge:[],
                signforSearchFlag:true,
                signforFlag:true,
                signforevaluateFlag:true,
                customerSignRate:[false,false,false],
                save_customerSignFlag:false,
                exceptionSaveFlag:true,
                exceptionMoifyFlag:true,
                exceptionDelFlag:true
            }
        },

        components:{ //引入组件后注册组件
            cesTable,
            Drawer
        },
        mounted(){
            this.getTableAndForm();
            let parentId = '2_2_5';
            let currentPageButtons = arrayQuery.text(parentId);
            let _this = this;
            _this.currentPageButtons = currentPageButtons;

            //查询按钮权限
            let exceptionSave = currentPageButtons.find(item => {
                return item.menuId == '2_2_5_1';
            });
            if(exceptionSave == undefined){
                _this.exceptionSaveFlag = false;
            }else {
                _this.exceptionSaveFlag = true;
            }

            //修改按钮权限
            let exceptionMoify = currentPageButtons.find(item => {
                return item.menuId == '2_2_5_3';
            });
            if(exceptionMoify == undefined){
                _this.exceptionMoifyFlag = false;
            }else {
                _this.exceptionMoifyFlag = true;
            }

            //删除按钮权限
            let exceptionDel = currentPageButtons.find(item => {
                return item.menuId == '2_2_5_4';
            });
            if(exceptionDel == undefined){
                _this.exceptionDelFlag = false;
            }else {
                _this.exceptionDelFlag = true;
            }
        },
        methods:{
            handleImgClick(imgurl){
                this.dialogImageUrl_via = `${this.$imageiPPath}`+imgurl.imgurl;
                this.dialogImageUrl_via_srcList.push(`${this.$imageiPPath}`+imgurl.imgurl);
                this.dialogVisible_imgUrl_flag = false;
                this.dialogVisible_imgUrl = true;
            },
            handleCloseImgDialog(){
                this.dialogImageUrl_via = '';
                this.dialogVisible_imgUrl_flag = false;
                this.dialogVisible_imgUrl = false;
                this.dialogImageUrl_via_srcList.length = 0;
            },
            //此方法是点击分页时触发的查询，
            handleCurrentChange(){
                this.exceptionQuery()
            },
            //获取表格和表单数据
            async getTableAndForm(){
                this.exceptionQuery()
            },
            handleQuery(){
                this.pagination.pageSize = 50;
                this.pagination.pageNum = 1;
                this.pagination.total = 0;
                //快捷查询
                this.loading = true;
                const data = new URLSearchParams();
                data.append('orderNo', this.AccurateAndUnique_orderNo);
                data.append('pageSize', this.pagination.pageSize);
                data.append('pageNo', this.pagination.pageNum);
                axios({
                    method: 'post',
                    url: `${this.$apiPath}/api/urban/exce/getExceptionPage`,
                    data:data
                }).then(res=> {
                    this.tableData = res.data.data.items;
                    this.pagination.total = res.data.data.total;
                    this.loading = false;
                });
            },
            // 获取表格勾选数据
            selectionLineChangeHandle(val){
                this.merge = this.$refs.cesTable.multipleSelection
            },
            //订单查询
            exceptionQuery(){
                this.loading = true;
                const data = new URLSearchParams();
                let crateTimeStrat;
                let crateTimeEnd;
                if(this.dataForms.crateTimeStrat == '' || this.dataForms.crateTimeStrat == null){
                    crateTimeStrat = ''
                }else {
                    crateTimeStrat = this.$moment(this.dataForms.crateTimeStrat).format("YYYY-MM-DD")
                }
                if(this.dataForms.crateTimeEnd == '' || this.dataForms.crateTimeEnd == null){
                    crateTimeEnd = ''
                }else {
                    crateTimeEnd = this.$moment(this.dataForms.crateTimeEnd).format("YYYY-MM-DD")
                }
                data.append('batchNo', this.dataForms.batchNo);
                data.append('exceType', this.dataForms.exceType);
                data.append('companyId', this.dataForms.companyId);
                data.append('companyName', this.dataForms.companyName);
                data.append('truckNo', this.dataForms.truckNo);
                data.append('crateTimeStrat', crateTimeStrat);
                data.append('crateTimeEnd', crateTimeEnd);
                data.append('pageSize', this.pagination.pageSize);
                data.append('pageNo', this.pagination.pageNum);
                axios({
                    method: 'post',
                    url: `${this.$apiPath}/api/urban/exce/getExceptionPage`,
                    data:data
                }).then(res=> {
                    this.tableData = res.data.data.items;
                    this.pagination.total = res.data.data.total;
                    this.loading = false;
                });
            },
            exceptionQuery_Multiple(){
                this.pagination.pageSize = 50;
                this.pagination.pageNum = 1;
                this.pagination.total = 0;
                this.exceptionQuery();
            },
            handleClose(done) {
                if (this.loading) {
                    return;
                }
                this.loading = true;
                this.timer = setTimeout(() => {
                    // 动画关闭需要一定的时间
                    setTimeout(() => {
                        this.loading = false;
                    }, 400);
                    this.exceptionQuery_Multiple()
                    // 动画关闭需要一定的时间
                    this.dialog = false;
                }, 2000);
            },
            cancelForm() {
                this.loading = false;
                this.dialog = false;
                clearTimeout(this.timer);
            },
            exceptionMoify(){

            },
            exceptionDel(){
                //删除
                this.selectionLineChangeHandle();
                var waybilldataIds = [];
                if(this.merge.length == 0){
                    this.$message({
                        type: 'info',
                        message: '删除操作必须选中一条数据'
                    });
                }else{
                    for(var i = 0;i<this.merge.length;i++){
                        waybilldataIds.push(this.merge[i].id)
                    }
                    axios({
                        method: 'post',
                        url: `${this.$apiPath}/api/urban/exce/delException`,
                        data:waybilldataIds
                    }).then(res=> {
                        if(res.data.success == true){
                            this.$message({
                                message: '删除成功',
                                type: 'success'
                            });
                            this.exceptionQuery();
                        }else {
                            this.$alert(res.data.msg, '提示', {
                                confirmButtonText: '确定',
                            });
                        }
                    });
                }
            }
        }
    }
</script>
<style>
    .tableMain .ces-table-page{
        height: 100%;
    }
    .tableMain .ces-table{
        height: 100%;
    }
    .tableMain .el-table th{
        padding: 0;
        height: 35px;
    }
    .tableMain .el-table td{
        padding: 0;
        height: 35px;
    }
</style>
<style scoped>

    .boxbutton{
        width: 100%;
        height: 40px;
    }
    .tableMain{
        width: 100%;
        height: 82%;
    }
</style>
